.content-section {
  @media screen {
    margin-bottom: theme('spacing.4');
    border-bottom: 1px solid var(--base-300);
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  p {
    margin-bottom: theme('spacing.4');
  }
}
