@use '@enzedonline/quill-blot-formatter2/dist/css/quill-blot-formatter2.css';

quill-view-html .ql-container .ql-editor {
  padding: 0 !important;
}

[quill-editor-toolbar] .ql-font span[data-label='Helvetica']::before {
  font-family: 'Helvetica Neue', sans-serif;
}

[quill-editor-toolbar] .ql-font span[data-label='Sans-serif']::before {
  font-family: sans-serif;
}

[quill-editor-toolbar] .ql-font span[data-label='Aref Ruqaa']::before {
  font-family: 'Aref Ruqaa', sans-serif;
}

[quill-editor-toolbar] .ql-font span[data-label='Mirza']::before {
  font-family: Mirza, sans-serif;
}

[quill-editor-toolbar] .ql-font span[data-label='Roboto']::before {
  font-family: Roboto, sans-serif;
}

.ql-font-helvetica {
  font-family: 'Helvetica Neue', sans-serif;
}

.ql-font-sanserif {
  font-family: sans-serif;
}

.ql-font-mirza {
  font-family: Mirza, sans-serif;
}

.ql-font-aref {
  font-family: 'Aref Ruqaa', sans-serif;
}

.ql-font-roboto {
  font-family: Roboto, sans-serif;
}
