@use '@cosmos/scss/settings' as s;

.cos-segmented-panel-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-row-title .cos-pill-label {
  margin-left: theme('spacing.2');
}

.form-panel-row {
  border-bottom: 1px solid theme('colors.base.300');

  &:not(:first-child) {
    padding-top: theme('spacing.4');
  }

  .cos-input-row {
    margin-bottom: theme('spacing.2');
    align-items: flex-start;
  }

  .cos-form-field {
    margin-bottom: 0;
  }

  @include s.wider-than-or-equal(theme('screens.md')) {
    esp-lookup-select {
      max-width: 18.75rem;
    }
  }
}

.form-panel-field {
  &__type {
    padding-right: 0;

    @include s.wider-than-or-equal(theme('screens.md')) {
      padding-right: theme('spacing.2');
      flex: 0;
      max-width: 13.75rem;
    }
  }

  &__add-field-btn.cos-button {
    margin-top: theme('spacing.2');
    margin-bottom: theme('spacing.4');
    padding-left: 0;
  }

  &__primary-msg {
    font-size: theme('fontSize.14');
    margin-bottom: 0;
  }
}

.form-panel-row-list {
  max-width: 50.5rem;
}
