@use '@cosmos/scss/settings' as s;

$width-default: 13.13rem;
$width-sm: 5.25rem;

.cos-card.image-upload-card {
  width: 100%;
  height: 6.875rem;
  max-width: $width-default;
  background-color: theme('colors.base.100');
  border: 1px solid theme('colors.base.400');

  .cos-card-container,
  .cos-card-body,
  .cos-card-main {
    width: 100%;
    height: 100%;
    padding: 0;
    min-height: 2rem;
  }

  .cos-card-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--small {
    width: $width-sm;
    height: $width-sm;

    img {
      max-width: $width-sm;
    }
  }

  .cos-card-controls-container {
    top: 0;
    right: 0;
  }

  .cos-card-body img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }
}

.cos-card.image-upload-card.image-upload-card--squared {
  aspect-ratio: 1;
  max-width: 100%;
  height: unset;
}
