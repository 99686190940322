@use '@cosmos/scss/settings' as s;

.cos-card {
  &.cos-card- {
    &p-0 {
      .cos-card-main {
        padding: 0;
      }
    }

    &p-4 {
      .cos-card-main {
        padding: theme('spacing.1');
      }
    }

    &p-8 {
      .cos-card-main {
        padding: theme('spacing.2');
      }
    }
  }

  &-header {
    @apply flex flex-row justify-between;

    > * {
      margin-bottom: 0;
    }

    > button {
      padding: 0;
    }
  }
}

.cos-card.accordion-card
  > .cos-card-container
  > .cos-card-body
  > .cos-card-main {
  padding: 0;

  > cos-accordion:last-of-type {
    margin-bottom: 0;
  }
}

.cos-card-wrapper {
  padding: theme('spacing.4');
  background-color: theme('colors.base.200');
}
