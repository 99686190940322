.product-results {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: theme('spacing.4');
  min-height: 100%;

  &-util-bar {
    margin-bottom: theme('spacing.6');
  }

  > a:hover {
    text-decoration: none;
  }
}

.item-list-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  > * {
    display: flex;
    flex-direction: column;
    flex-basis: 33.3333%;
    padding-right: theme('spacing.4');
  }
}

.product-accordion {
  > cos-card > .cos-card-container > .cos-card-body > .cos-card-main {
    padding: 0;
  }

  cos-accordion:last-of-type {
    margin-bottom: 0;
  }
}

esp-product-options {
  display: block;
}

esp-product-charges-table,
esp-product-charges {
  display: block;
  margin-bottom: theme('spacing.4');
}
