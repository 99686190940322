@use '@cosmos/scss/settings' as s;

esp-encore-layout .loader.progress-dark {
  background-color: theme('colors.base.300');
  height: 0.6875rem;
  border-radius: 6px;

  &::before {
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 5%),
      transparent
    ) !important;
  }
}
