@use 'cosmos/scss/theme-styles/esp';
@use './base' as encore_base;
@use './components' as encore_components;
@use '@cosmos/scss/global';
@use '@cosmos/scss/tailwind';
@use 'tailwindcss/utilities' as tw_utilities;

body {
  margin: 0;

  @media screen {
    background-color: theme('colors.base.200');
  }

  @media print {
    background-color: theme('colors.white');
  }
}

body.client-safe-mode .client-sensitive-data {
  display: none;
}

.scaled-autocomplete {
  transform: scaleX(1.01);
}
