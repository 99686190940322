@use '@cosmos/scss/settings' as s;

/* COS FORM FIELD STYLING */

/* TODO(caio): Full review on how search input is handled */
.cos-search-field {
  position: relative;
  text-align: left;
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid theme('colors.base.500');
  border-radius: 4px;
  background: theme('colors.base.100');

  &:focus-within {
    @include s.cos-focus-input;
  }

  &--disabled {
    border-color: var(--input__disabled__border-color, var(--base-400));
    background-color: var(--input__disabled__bg, var(--base-300));
    color: var(--input__disabled__color, var(--base-400));
  }

  input {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    border: none;
    background: transparent;
    padding-left: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .form-field-prefix {
    color: rgb(189, 192, 196);
  }

  .form-field-prefix,
  .form-field-suffix {
    line-height: inherit;
    white-space: nowrap;
    flex: none;
    position: relative;
    width: 1.875rem;
    text-align: center;
  }
}

button.actions-button {
  padding: 0;
  min-height: 2rem;
  min-width: 2rem;
}

.search-filters button {
  border: 1px solid theme('colors.base.300');
  background-color: theme('colors.base.100');
  font-size: 0.85rem;
  padding: theme('spacing.2') theme('spacing.4');
}

.filter-wrapper {
  background: theme('colors.base.100');
  border-bottom: 1px solid theme('colors.base.300');
}

.search-header {
  display: grid;
  grid-template-areas:
    'a b'
    'c d'
    'e e'
    'f f';

  &__title {
    grid-area: 1/1 / auto/span 2;
    min-width: 50%;
    margin-bottom: theme('spacing.2');
  }

  &__actions {
    grid-area: d;
    flex: 1 0 50%;
    justify-self: right;
    margin-bottom: theme('spacing.2');
  }

  &__keywords {
    grid-area: c;
    flex: 0 0 100%;
    margin-bottom: theme('spacing.6');
    max-width: 23.75rem;
  }

  &__tabs {
    grid-area: e;
    flex: 0 0 100%;
    margin-bottom: theme('spacing.2');
    max-width: 100%;
  }

  &__sort {
    grid-area: f;
    justify-self: right;
    flex: 0 0 100%;
    margin-bottom: theme('spacing.3');
  }
}

@include s.narrower-than(theme('screens.md')) {
  .search-header {
    display: flex;
    flex-direction: column;
  }
}
