@use '@cosmos/scss/settings' as s;

$icon-font-family: 'Font Awesome 6 Free';

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 87%);
}

// TODO(FP): instead of having global styles, would be better to have re-usable variables.
// These styles should be moved to the component level.
.filter-menu {
  &.filter-menu-select-list fieldset {
    display: flex;
    flex-wrap: wrap;
    width: 23.75rem;
    min-width: 0;

    @include s.narrower-than(theme('screens.lg')) {
      width: 100%;
    }
  }

  .cos-selection-list,
  .filter-menu-selection-list {
    width: 100%;
    max-height: 16.5rem;
    overflow-y: auto;
    padding-top: 0;
  }
}

.mat-ripple {
  overflow: hidden;
}
